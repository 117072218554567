// Form
.form-container-light-background {
  background-color: #f1f5f9;
  label {
    color: #0f172a;
  }
  input,
  select {
    color: #0f172a;
  }
  h3 {
    color: #020617;
  }
  h2 {
    color: #020617;
  }
}

.form-container-dark-background {
  background: #0f172a;
  label {
    color: #cbd5e1;
  }
  input,
  select {
    color: #e2e8f0;
    background: #020617;
  }
  h3,
  p,
  h2 {
    color: #f8fafc;
  }
}

.form-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 90%;
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin-top: 40px;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    margin-top: 40px;
  }
  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 16.2px;
    // color: #0f172a;
  }
  input,
  select {
    padding: 10px 16px 10px 16px;
    height: 48px;
    border-radius: 10px;
    border: 1px;
    margin-bottom: 10px;
  }
  input[type="tel"] {
    padding-left: 40px;
  }
  input:focus-visible {
    outline: none;
  }
  // select {
  //   padding: 10px 16px 10px 16px;
  //   height: 48px;
  //   border-radius: 10px;
  //   border: 1px;
  //   margin: 10px 0;
  //   color: #0f172a;
  //   width: 100%;
  //   font-size: 16px;
  // }
  @media screen and (min-width: 810px) {
    width: 60%;
  }
  @media screen and (min-width: 1020px) {
    width: 45%;
  }
  @media screen and (min-width: 1440px) {
    width: 32%;
  }
}

.form-donation {
  margin-top: 30px;
}

.light-form-amount {
  color: #020617;
}

.dark-form-amount {
  color: #f8fafc;
}

.form-amount {
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
}

.light-form-donation-text {
  color: #64748b;
}

.dark-form-donation-text {
  color: #cbd5e1;
}

.form-donation-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
}

.form-card-container {
  border: 0.5px solid #cbd5e1;
  border-radius: 12px;
  padding: 20px;
  margin: 10px 0;
}

.edit-about-you {
  display: flex;
  img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
}

.on-edit {
  display: flex;
  justify-content: space-between;
}

.edit-icon {
  height: 20px;
  margin: 10px 0 0 10px;
  cursor: pointer;
}

.amount-card-light-background {
  background: #e2e8f0;
}

.amount-card-dark-background {
  background: #020617;
}

.form-donation-amount-card {
  margin-top: 30px;
  padding: 12px 23px 12px 23px;
  border-radius: 12px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  // > span,
  // input {
  //   flex-basis: 50%;
  // }
  .donate-light-input {
    background: transparent;
  }
  .donate-dark-input {
    background: transparent;
    color: #e2e8f0;
  }
}

.light-dollar-sign {
  color: #020617;
}

.dark-dollar-sign {
  color: #e2e8f0;
}

.form-donation-card-dollar-sign {
  font-weight: 500;
  font-size: 24px;
  line-height: 32.4px;
}

.form-donation-card-amount {
  font-weight: 600;
  font-size: 36px;
  line-height: 48.6px;
  color: #020617;
  margin-left: 5px;
}

.about-you {
  display: flex;
  justify-content: space-between;
}

.form-data {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.about-you-data {
  position: relative;
  display: flex;
  flex-direction: column;
  // img {
  //   height: 20px;
  //   width: 20px;
  //   position: absolute;
  //   top: 130px;
  //   left: 10px;
  //   transform: translateY(-50%);
  // }
}

.edit-about-you-data {
  display: flex;
}

.credit-card-style {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 810px) {
    flex-direction: row;
  }
}

.light-card-digit-ending {
  color: #020617;
}

.dark-card-digit-ending {
  color: #f1f5f9;
}

.light-expiry-date {
  color: #64748b;
}

.dark-expiry-date {
  color: #cbd5e1;
}

.credit-card-expiry-detail {
  display: flex;
  flex-direction: column;
  margin: 7px 0 0 10px;
  .card-digit-ending {
    font-weight: 500;
    font-size: 14px;
    line-height: 18.9px;
  }
  .expiry-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.2px;
  }
}

.editing {
  display: flex;
  margin-top: 20px;
}

.payment-data {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;

  div {
    flex-grow: 1;
  }
  input {
    width: 100% !important;
  }
}

.edit-payment-data {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
}

.card-number {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-card-number {
  display: flex;
}

.expiry {
  width: 100%;
  input {
    text-align: left;
    @media screen and (min-width: 810px) {
      text-align: center;
    }
  }
  @media screen and (min-width: 810px) {
    width: 50px;
    margin: -20px 0 0 10px;
  }
}

.cvv {
  width: 100%;
  margin-left: 10px;
  input {
    text-align: left;
    @media screen and (min-width: 810px) {
      text-align: center;
    }
  }
  @media screen and (min-width: 810px) {
    width: 15%;
    margin-top: -20px;
  }
}

.light-error-bg {
  background: #eccbcb;
}

.dark-error-bg {
  // background: #eccbcb;
  background-color: rgba(240, 93, 93, 0.1);
  backdrop-filter: blur(5px);
}

.unsuccessful-payment {
  display: flex;
  align-items: center;
  border-left: 3px solid #ef4444;
  margin-top: 10px;
  height: 70px;
  padding-left: 13px;
  column-gap: 18px;
  p {
    color: #ef4444;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.9px;
    width: 70%;
  }
  img {
    height: 20px;
  }
}

.form-donation-message {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18.9px;
    margin-left: 10px;
  }
}

.light-form-message {
  color: #475569;
}

.dark-form-message {
  color: #cbd5e1;
}

.phone-input {
  background-color: #020617;
}

.form-message {
  display: flex;
  width: 280px;
}

/* Track */
.react-toggle-track {
  background-color: #888; /* Change track color */
}

/* Thumb */
.react-toggle-thumb {
  background-color: #fff; /* Change thumb color */
}

/* Thumb hover state */
.react-toggle:hover .react-toggle-thumb--checked:hover {
  background-color: #fff; /* Maintain thumb color on hover */
}

/* Checked hover state */
.react-toggle--checked:hover .react-toggle-track:hover {
  background-color: #4372ee; /* Change track color when toggle is checked */
}

/* Checked state */
.react-toggle--checked .react-toggle-track {
  background-color: #4372ee; /* Change track color when toggle is checked */
}

.dark-textarea {
  background: #020617;
  color: #cbd5e1;
}

.light-edit-icon-bg {
  background: #f8fafc;
}

.dark-edit-icon-bg {
  background: #020617;
}

.edit-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.form-footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  textarea {
    padding: 12px 16px 12px 16px;
    border-radius: 6px;
    margin-top: 20px;
  }
  button {
    background: #4372ee;
    padding: 12px 24px 12px 24px;
    margin-top: 20px;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
  }

  button:disabled {
    background: #98bbf8;
    cursor: not-allowed;
  }
}

// Center page downward
.charge-container {
  background: #2e53e2;
  color: #f0f5fe;
  padding: 2rem 0 2rem 3rem;
  @media screen and (min-width: 768px) {
    padding: 3rem 0 3rem 4rem;
  }
  @media screen and (min-width: 1024px) {
    padding: 4rem 0 4rem 6rem;
  }
  @media screen and (min-width: 1280px) {
    padding: 5rem 0 5rem 8rem;
  }
  @media screen and (min-width: 1366px) {
    padding: 9rem 0 9rem 13rem;
  }
  .img-container {
    width: 80%;
    height: 80%;
    @media screen and (min-width: 768px) {
      // width: 100%;
      // height: 100%;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 8px;
    line-height: 10.8px;
    @media screen and (min-width: 768px) {
      font-weight: 600;
      font-size: 12px;
      line-height: 16.2px;
    }
  }
  .top-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 16.2px;
    width: 85%;
    margin-top: 0.5rem;
    @media screen and (min-width: 768px) {
      font-size: 14px;
      line-height: 24.2px;
      width: 45%;
    }
    @media screen and (min-width: 1024px) {
      font-size: 28px;
      line-height: 36.2px;
      width: 55%;
      margin-top: 1rem;
    }
    @media screen and (min-width: 1280px) {
      font-size: 32px;
      line-height: 43.2px;
      width: 48%;
    }
    @media screen and (min-width: 1366px) {
      width: 47%;
    }
    @media screen and (min-width: 1440px) {
      width: 45%;
    }
    @media screen and (min-width: 1600px) {
      width: 40%;
    }
    @media screen and (min-width: 1680px) {
      width: 38%;
    }
    @media screen and (min-width: 1920px) {
      width: 33%;
    }
  }
  .bottom-description {
    font-weight: 500;
    font-size: 10px;
    line-height: 13.5px;
    width: 85%;
    @media screen and (min-width: 768px) {
      font-size: 14px;
      line-height: 24.2px;
      width: 85%;
    }
    @media screen and (min-width: 1024px) {
      font-size: 28px;
      line-height: 36.2px;
      width: 95%;
    }
    @media screen and (min-width: 1280px) {
      font-size: 32px;
      line-height: 43.2px;
      width: 85%;
    }
    @media screen and (min-width: 1366px) {
      width: 83%;
    }
    @media screen and (min-width: 1440px) {
      width: 80%;
    }
    @media screen and (min-width: 1600px) {
      width: 70%;
    }
    @media screen and (min-width: 1680px) {
      width: 68%;
    }
    @media screen and (min-width: 1920px) {
      width: 58%;
    }
  }
}

.give-campaign-light-container {
  background: #f1f5f9;
}

.give-campaign-dark-container {
  background: #0f172a;
}

.benefits-of-giving {
  position: relative;
  display: flex;
  margin-left: auto;
  width: 82%;
  height: 200px;
  color: white;
  @media screen and (min-width: 768px) {
    width: 85%;
    height: 450px;
  }
  @media screen and (min-width: 1028px) {
    width: 85%;
    height: 500px;
  }
}

.benefits-of-giving::before {
  content: "";
  background-image: url("/images/benefit_of_giving.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  filter: brightness(50%);
  border-radius: 0 0 0 16px;
  color: white;
}

.benefits-of-giving > div {
  position: relative;
  z-index: 2;
  padding: 38px 60px 48px 27px;
  @media screen and (min-width: 768px) {
    padding: 68px 100px 75px 90px;
  }
}

.benefits-of-giving-header {
  width: 100%;
  h2 {
    font-weight: 600;
    font-size: 8px;
    line-height: 10.8px;
    width: 213.59px;
    margin-top: 3px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 22.4px;
      margin-top: 8px;
      width: 85%;
    }
    @media screen and (min-width: 977px) {
      font-size: 17px;
      line-height: 32.4px;
    }
    @media screen and (min-width: 1028px) {
      font-size: 18px;
      // line-height: 32.4px;
    }
    @media screen and (min-width: 1074px) {
      font-size: 19px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1118px) {
      font-size: 20px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1165px) {
      font-size: 21px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1213px) {
      font-size: 22px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1261px) {
      font-size: 23px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1305px) {
      font-size: 24px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1353px) {
      font-size: 25px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1401px) {
      font-size: 26px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1449px) {
      font-size: 27px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1497px) {
      font-size: 28px;
      //   line-height: 32.4px;
    }
    @media screen and (min-width: 1541px) {
      //   font-size: 29px;
      line-height: 32.4px;
      width: 80%;
    }
    @media screen and (min-width: 1625px) {
      font-size: 30px;
      line-height: 32.4px;
      width: 80%;
    }
  }
  h3 {
    font-weight: 400;
    font-size: 4px;
    line-height: 5.6px;
    margin-top: -8px;
    @media screen and (min-width: 768px) {
      font-size: 12px;
      line-height: 16.4px;
    }
    @media screen and (min-width: 977px) {
      font-size: 16px;
      line-height: 22.4px;
    }
    @media screen and (min-width: 1450px) {
      font-size: 18px;
      line-height: 24.4px;
    }
    @media screen and (min-width: 1450px) {
      font-size: 20px;
    }
  }
}

.details-of-benefits {
  display: flex;
  margin-top: 18px;
  font-weight: 400;
  font-size: 4px;
  line-height: 5.4px;
  @media screen and (min-width: 768px) {
    font-size: 12px;
    line-height: 16.4px;
    margin-top: 30px;
  }
  @media screen and (min-width: 977px) {
    font-size: 13px;
    line-height: 17.4px;
    margin-top: 26px;
  }
  @media screen and (min-width: 1450px) {
    font-size: 18px;
    line-height: 24.4px;
  }

  .top-left-detail {
    width: 73px;
    margin-top: -4px;
    @media screen and (min-width: 768px) {
      width: 40%;
      margin-top: 1px;
    }
    @media screen and (min-width: 977px) {
      width: 37%;
    }
    @media screen and (min-width: 1280px) {
      width: 32%;
    }
    @media screen and (min-width: 1381px) {
      width: 31%;
    }
    @media screen and (min-width: 1449px) {
      width: 40%;
    }
  }
  .top-right-detail {
    width: 70px;
    margin-left: 10px;
    @media screen and (min-width: 768px) {
      width: 35%;
      margin-left: 30px;
    }
    @media screen and (min-width: 1280px) {
      width: 28%;
    }
  }
  .bottom-left-detail {
    width: 96.16px;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      width: 38%;
    }
    @media screen and (min-width: 1111px) {
      width: 36%;
    }
    @media screen and (min-width: 1180px) {
      width: 35%;
    }
    @media screen and (min-width: 1280px) {
      width: 30%;
    }
  }
  .bottom-right-detail {
    width: 96.16px;
    margin: 15px 0 0 10px;
    @media screen and (min-width: 768px) {
      width: 35%;
      margin-left: 30px;
    }
    @media screen and (min-width: 1280px) {
      width: 28%;
    }
  }
  .top-divider {
    margin-left: 10px;
    height: 23.74px;
    @media screen and (min-width: 768px) {
      height: 100%;
    }
  }
  .bottom-divider {
    margin: 15px 0 0 10px;
    height: 34.03px;
    @media screen and (min-width: 768px) {
      height: 80%;
      margin-left: 25px;
    }
    @media screen and (min-width: 1028px) {
      height: 100%;
    }
  }
}

.make-a-difference-light-mode {
  color: #020617;
}

.make-a-difference-dark-mode {
  color: #f8fafc;
}

.make-a-difference {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    margin: 0 auto 20px auto;
    width: 200px;
    text-align: center;
    @media screen and (min-width: 768px) {
      font-size: 28px;
      line-height: 37.8px;
      width: 60%;
    }
    @media screen and (min-width: 977px) {
      font-size: 29px;
    }
    @media screen and (min-width: 1028px) {
      font-size: 30px;
    }
    @media screen and (min-width: 1074px) {
      font-size: 31px;
    }
    @media screen and (min-width: 1118px) {
      font-size: 32px;
    }
    @media screen and (min-width: 1165px) {
      font-size: 33px;
    }
    @media screen and (min-width: 1213px) {
      font-size: 34px;
    }
    @media screen and (min-width: 1261px) {
      font-size: 35px;
    }
    @media screen and (min-width: 1305px) {
      font-size: 36px;
    }
    @media screen and (min-width: 1353px) {
      font-size: 37px;
    }
    @media screen and (min-width: 1401px) {
      font-size: 38px;
    }
    @media screen and (min-width: 1449px) {
      font-size: 39px;
    }
    @media screen and (min-width: 1497px) {
      font-size: 40px;
    }
    @media screen and (min-width: 1541px) {
      font-size: 41px;
    }
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.2px;
    text-align: center;
    width: 260px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 22.4px;
      width: 500px;
    }
    @media screen and (min-width: 977px) {
      font-size: 18px;
      line-height: 24.4px;
      width: 550px;
    }
  }

  a,
  a:hover {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.card-container {
  background: transparent;
  width: 304.33px;
  height: 383px;
  border-radius: 8px;
  padding: 24px;
  margin-top: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.card-container:hover {
  background: #4372ee;
  width: 304.33px;
  height: 383px;
  border-radius: 8px;
  padding: 24px;
  margin-top: 50px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  p {
    color: #ffffff;
  }
  button {
    background-color: #ffffff;
    color: #4372ee;
  }
  img {
    filter: brightness(0) invert(1); /* Change color to white on hover */
  }
}

.card-container.active {
  animation: shake 0.5s ease-in-out;
}

.card-popular-container.active {
  animation: shake 0.5s ease-in-out;
}

.card-popular-container {
  background: #4372ee;
  width: 304.33px;
  height: 383px;
  border-radius: 8px;
  padding: 24px;
  margin-top: 50px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  // p {
  //   color: #ffffff
  // }
  // button {
  //   background-color: #ffffff;
  //   color: #4372ee;
  // }
  // img{
  //   filter: brightness(0) invert(1); /* Change color to white on hover */
  // }
}

.card-container.active {
  animation: shake 0.5s ease-in-out;
}

.donate-light-theme {
  color: #475569;
}

.donate-dark-theme {
  color: #cbd5e1;
}

.card-container-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
  }
}

.popular-card-container-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
  }
}

.popular-card {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius: 24px 0px 0px 24px;
  padding: 4px 12px 4px 12px;
  width: 68px;
  height: 26px;
  background: #6d90f0;
  margin-right: -23px;
}

.card-donation-amount {
  display: flex;
  p {
    font-weight: 600;
    font-size: 48px;
    line-height: 55.2px;
    color: #4372ee;
  }
  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-top: 25px;
    color: black;
  }
}

.card-donation-amount:hover {
  display: flex;
  p {
    font-weight: 600;
    font-size: 48px;
    line-height: 55.2px;
  }
  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-top: 25px;
  }
}

.popular-card-donation-amount {
  display: flex;
  p {
    font-weight: 600;
    font-size: 48px;
    line-height: 55.2px;
  }
  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-top: 25px;
  }
}

.guarantees {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #4372ee;
  margin: 30px 0 20px 0;
}

.popular-guarantees {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #d6d9e7;
  margin: 30px 0 20px 0;
}

.guarantee-light-mode {
  color: #020617;
}

.guarantee-dark-mode {
  color: #f8fafc;
}

.guarantee-description {
  display: flex;
  p {
    margin-left: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 10px;
  }
}

.popular-guarantee-description {
  display: flex;
  p {
    margin-left: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 10px;
  }
}

.card-icon {
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 10px;
  }
}

.card-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4372ee;
  color: white;
  width: 256.33px;
  border: 1px;
  border-radius: 100px;
  padding: 12px 24px 12px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18.9px;
  margin-top: 20px;
}

.popular-card-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #4372ee;
  width: 256.33px;
  border: 1px;
  border-radius: 100px;
  padding: 12px 24px 12px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18.9px;
  margin-top: 20px;
  cursor: pointer;
}

.mapped-donation-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1020px) {
    flex-direction: row;
    justify-content: center;
    column-gap: 5px;
  }
}

.sub-heading {
  text-align: start;
}

.carousel {
  padding: 60px 0 30px 0;
  h2,
  h3,
  p {
    position: relative;
    bottom: 7rem;
    margin-left: 3rem;
    @media screen and (min-width: 470px) {
      margin-left: 6.7rem;
      bottom: 6rem;
    }
    @media screen and (min-width: 720px) {
      bottom: 10rem;
      margin-left: 8.7rem;
    }
    @media screen and (min-width: 1020px) {
      bottom: 15rem;
      margin-left: 12.7rem;
    }
    @media screen and (min-width: 1220px) {
      bottom: 20rem;
      margin-left: 13.3rem;
    }
    @media screen and (min-width: 1320px) {
      bottom: 20rem;
      margin-left: 10.8rem;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 3.82px;
    line-height: 5.16px;
    @media screen and (min-width: 470px) {
      font-size: 4px;
      line-height: 8.9px;
    }
    @media screen and (min-width: 720px) {
      font-weight: 400;
      font-size: 8px;
      line-height: 15.9px;
    }
    // @media screen and (min-width: 1020px) {
    //   font-size: 10px;
    //   line-height: 15.9px;
    // }
    @media screen and (min-width: 1220px) {
      font-size: 14px;
      line-height: 18.9px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 12.02px;
    line-height: 13.23px;
    width: 22rem;
    @media screen and (min-width: 720px) {
      font-size: 18px;
      line-height: 24.4px;
      width: 32rem;
    }
    @media screen and (min-width: 1020px) {
      font-size: 24px;
      line-height: 32.4px;
      width: 40rem;
    }
    @media screen and (min-width: 1220px) {
      font-size: 44px;
      line-height: 59.4px;
      width: 75rem;
    }
  }
  p {
    font-weight: 500;
    font-size: 3.82px;
    line-height: 5.16px;
    width: 30rem;
    @media screen and (min-width: 470px) {
      font-size: 5px;
      line-height: 10.9px;
      width: 22rem;
    }
    @media screen and (min-width: 720px) {
      font-weight: 400;
      font-size: 8px;
      line-height: 14px;
      width: 34rem;
    }
    @media screen and (min-width: 1020px) {
      font-size: 14px;
      line-height: 21px;
      width: 55rem;
    }
    @media screen and (min-width: 1220px) {
      font-size: 17px;
      line-height: 24px;
      width: 67rem;
    }
    @media screen and (min-width: 1320px) {
      font-size: 20px;
      line-height: 27px;
      width: 80rem;
    }
  }
}

.carousel .carousel-slider .control-dots .dot {
  background-color: #ffffff;
  // border: 1px solid #FFFFFF;
  width: 6px;
  height: 3px;
  border-radius: 4px;
  opacity: 0.3;
  @media screen and (min-width: 1020px) {
    width: 16px;
    height: 10px;
    border: 1px solid #ffffff;
  }
}
.carousel .carousel-slider .control-dots .dot.selected {
  background-color: #ffffff;
  opacity: 1;
}

.carousel.carousel-slider .control-dots {
  bottom: 6.8rem;
  right: 9.2rem;
  @media screen and (min-width: 470px) {
    bottom: 7rem;
    right: 8.7rem;
  }
  @media screen and (min-width: 500px) {
    right: 9.9rem;
  }
  @media screen and (min-width: 570px) {
    right: 12.5rem;
  }
  @media screen and (min-width: 630px) {
    right: 14.8rem;
  }
  @media screen and (min-width: 720px) {
    bottom: 10.5rem;
    right: 16.2rem;
  }
  @media screen and (min-width: 830px) {
    right: 20.4rem;
  }
  @media screen and (min-width: 1020px) {
    bottom: 14.5rem;
    right: 19.8rem;
  }
  @media screen and (min-width: 1100px) {
    right: 22.5rem;
  }
  @media screen and (min-width: 1200px) {
    right: 26rem;
  }
  @media screen and (min-width: 1220px) {
    bottom: 18.7rem;
  }
  @media screen and (min-width: 1300px) {
    right: 29.7rem;
  }
  @media screen and (min-width: 1320px) {
    right: 32.4rem;
  }
  @media screen and (min-width: 1345px) {
    right: 33.2rem;
  }
  @media screen and (min-width: 1400px) {
    right: 35.2rem;
  }
  @media screen and (min-width: 1500px) {
    right: 38.8rem;
  }
  @media screen and (min-width: 1600px) {
    right: 42.4rem;
  }
  @media screen and (min-width: 1700px) {
    right: 45.9rem;
  }
  @media screen and (min-width: 1800px) {
    right: 49.2rem;
  }
}

// Footer
.footer-light-container {
  background: white;
  border-top: 0.5px solid #cbd5e1;
  padding-bottom: 20px;
}

.footer-dark-container {
  background: #020617;
  border-top: 0.5px solid #64748b;
  padding-bottom: 20px;
}

.food-pantry {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  h3 {
    color: #2540d0;
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    margin: 10px 0 0 5px;
  }
}

.prioritise-security-light-mode-text {
  color: #334155;
}

.prioritise-security-dark-mode-text {
  color: #e2e8f0;
  img {
    color: #e2e8f0;
  }
}

.prioritise-security {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18.9px;
    margin: 3px 0 0 5px;
  }
  img {
    height: 20px;
  }
}

.show-more-light-mode {
  color: #64748b;
}

.show-more-dark-mode {
  color: #cbd5e1;
}

.show-more {
  font-weight: 500;
  font-size: 12px;
  line-height: 16.2px;
  text-align: center;
  margin-top: 15px;
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  font-family: "General-sans" !important;

  @media screen and (max-width: 768px) {
    max-width: 22rem;
  }
}

.app-download-button {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.church-logo {
  text-align: center;
  margin: 30px 0;
}

// Success Modal

.modal-container {
  // display: flex;
  // justify-content: center;
  // margin-top: 50px;
  // margin-left: 680px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 2rem;
  @media screen and (max-width: 768px) {
    top: 3rem;
    right: 3rem;
  }
}

.modal-content {
  background-color: #f1f5f9;
  width: 600px;
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.content {
  margin: 0 auto;
  text-align: center;
  width: 250px;
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32.4px;
    color: #020617;
  }
  p {
    font-family: 400;
    font-size: 14px;
    line-height: 18.9px;
    color: #64748b;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    // width: auto;
    row-gap: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.homepage-button {
  color: #ffffff;
  background-color: #4372ee;
  padding: 12px 24px 12px 24px;
  width: 174px;
  border-radius: 1000px;
  margin: 0 auto 20px auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 18.9px;
}

.homepage-button:hover {
  background-color: #114cf0;
}

.credit-card > div {
  padding: unset !important;
  background-color: unset !important;
  border: unset !important;
  box-shadow: unset !important;
  display: unset !important;
  border-radius: unset !important;
  height: unset !important;
}
#give-campaign-v2 .PhoneInput {
  background-color: #fff;
  border-radius: 10px;
  padding-left: 10px;
  .PhoneInputCountry {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  input {
    padding-left: 3px !important;
    margin-bottom: 0;
    padding-left: 3px !important;
  }
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #4372ee;
}

button {
  font-weight: 600;
  font-size: 14px;
  font-family: "General-sans";
}
#give-campaign-v2 {
  p,
  input,
  div,
  span,
  button,
  select,
  li,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "General-sans" !important;
  }
  @media screen and (max-width: 768px) {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
}

.service-provided {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 18px;
}

.loader-wrapper {
  padding: 80px;
  border: dashed 1px #9747ff;
  border-radius: 20px;
  background-color: rgba(151, 71, 255, 0.28);
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.skeleton-container {
  width: 100%;
  height: 40px;
  border-radius: 4px;
}
.campaign-loader {
  width: 95%;
  height: 95%;
  display: flex;
}
