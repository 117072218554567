.mainsection {
  height: 380px;
}
// .mainsection-container{
//   top: -25vh;
// }

.give-section {
  height: 640px;
  padding-top: 10rem;
  // margin-bottom: 22rem;
}
.give-without {
  padding-top: 10rem;
  // height: 400px;
  height: 440px;
}

.one-give-option {
  // padding-top: 8rem;
  // height: 520px;
  padding-top: 10rem;
  height: 565px;
}

.full-width {
  width: 100%;
}

.payment-switch {
  position: relative;
  bottom: 5.7rem;
}

@media (min-width: 576px) {
  .give-section {
    // height: 500px;
    // margin-bottom: 4rem;
    // padding-top: 7.4rem;
  }
  // .mainsection {
  //   height: 60vh;
  // }
}

@media (max-width: 560px) {
  .give-section {
    height: 595px;
    padding-top: 11.3rem;
  }
  .one-give-option {
    // height: 440px;
    height: 500px;
    padding-top: 11rem;
  }
  .mainsection {
    // height: 350px;
    height: 365px;
  }
}

#field-wrapper.input {
  border: 1px solid #bdbdbd;
  padding: 20px 10px;
}

#field-wrapper.input > :nth-child(2) {
  flex: 1;
}
