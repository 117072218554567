.placeholder{
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* background-color: #390096; */
}

.formSwitchGroup a{
  font-size: 13px;
}
.formSwitchGroup span span span{
  font-size: 13px;
}
.formSwitch label{
  font-size: 11px;
  line-height: 20px;
  color: #7E8299;
}
.icon{
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 20px;
  border-radius: 100%;
  background-color: rgb(50, 130, 184);
}
.password span{
  right: 25px;
  top: 24%;
  cursor: pointer;
}
.icon i{
  font-size: 30px;
  color: #fff;
}
.mobile input{
  border: none;
  background: none;
  margin-right: 10px;
  /* color:  #3F4254;; */
}
.mobile input::placeholder{
  color: #bfc0c4;
}
.mobile input:focus{
  outline: none;
}
.email-icon{
  top: 40%;
  left: 13px;
  font-size: 1.0rem;
}
.key-icon{
  font-size: 1.1rem;
  left: 11px;
}


.errorText {
  font-size: .9rem !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.forgetPasswordContainter {
  padding-bottom: 8px;
}

.requestBtn{
  color: #fff
}

@media (max-width: 576px) {
  .formSwitch {
    margin-bottom: 10px;
  }
}

.disabled-cursor{
  cursor:not-allowed !important;
}