.navbar-nav {
  flex-direction: column;
}
.navbar{
  .nav-item{
    font-size: 14px;
  }
}

.hamburger{
  padding: 5px 10px;
  border: 1px solid #979797;
  border-radius: 5px;
}

.rsvp-dropdown-menu{
  .MuiMenu-paper{
    top: 45px !important;
  }
  // position: absolute;

}

// .navbar-light .navbar-nav .nav-link:hover
//   {
//   background-color: #3699ff !important;
//   color: #fff;
//   border-radius:5px;
//   span {
//     color: #fff
//   }
// }


// .normal {
//   color: #fff !important;
//   background-color: transparent !important;
  // border-radius:5px;
// }
// .active {
//   color: #fff !important;
//   background-color: #3699ff !important;
//   border-radius:5px;
// }

.subVisible {
  display: none;
}

.subnavbar {
  .msg{
    font-size: 1rem;
  }
  .churchName{
    font-size: 1.5rem;
  }
}


  @media (min-width: 576px) {
    .navbar-nav {
      flex-direction: row;
    }

    

    // .subVisible {
    //  display: inline-block;
    // }
  }
  