.pincode-input-container
{
  display: flex;
  justify-content: space-around;
  .pincode-input-text
  {
    padding:0 !important;
    // margin:0 2px;
    text-align:center;
    // border: 1px solid;
    background: transparent;
    // width: 50px;
    // height: 50px;
    border-radius: 5px;
    font-size: 45px;
    height: 50px;
    width: 50px;
    border: 1px solid gray;
    // margin: 1%;
  }
  .pincode-input-text:focus
  {
    outline:none;
    box-shadow:none;
    transform: scale(1.1);
  }
}

@media screen and (max-width: 480px) {
  .pincode-input-container
{
  display: flex;
  justify-content: space-around;
  .pincode-input-text
  {
    padding:0 !important;
    text-align:center;
    background: transparent;
    border-radius: 5px;
    font-size: 45px;
    height: 40px !important;
    width: 40px !important;
    border: 1px solid gray;
  }
  .pincode-input-text:focus
  {
    outline:none;
    box-shadow:none;
    transform: scale(1.1);
  }
}
}