#card {
  height: 200px;
  width: 100%;
  max-width: 385px;
  position: relative;
  /* top: -100px; */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-position: center;
  color: #eee;
  filter: drop-shadow(1px 3px 10px #222);

  animation: fadeIn .2s linear 1;
}

@keyframes fadeIn {
  from {
    top: -150px;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#card .header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}

#card .sticker {
  width: 50px;
  height: 40px;
  background-image: url("https://images.pexels.com/photos/728471/pexels-photo-728471.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
  background-position: center;
  background-size: cover;
  object-fit: contain;
  border-radius: 5px;
}

#card .logo {
  max-width: 60px;
}

#card .body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60%;
}

#card .footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}