.spouse_image {
  width:10%;
  margin-left: 10px;
}

.spouse-wrapper {
  width: 100%;
  .user-image-holder{
    width: 150px;
    height: 150px;
    border-radius: 80px;
    padding-top: .8rem
  }
}

.un-set-spouse {
  cursor: pointer;
  font-size: 16px;
}

.icon-size {
  font-size: 16px;
}
@media (min-width: 576px) {
.spouse_image {
  width: 7%;
  // margin-left: 10px;
}
.un-set-spouse {
  font-size: 16px;
}
}

@media screen and (max-width: 720px) {
  .spouse-holder{
    display: none;
  }
  .spouse-small-screen {
    display: block;
  }
  .spouse-details {
    display: block !important;
    padding: 0px;
  }
  .spouse-divide {
    display: none;
  }
 
  .spouse_image {
    width: 10%;
    margin: 0px;
    padding-bottom: .3rem;
  }
  .user-image-holder{
    padding-top: 0.8rem;
  }
}

.spouse-small-screen {
  display: none;
}

.table td {
  vertical-align: middle;
}
