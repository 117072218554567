.page-wrapper {
  border-radius: 4px;

 .btn-outline-brand, i {
    color: #374afb;
    border-color: #374afb; 
   
    // &:hover {
    //   color: #fff;
    //   background-color: #374afb;
    // }
 }
 .amount-holder {
   font-size:4rem;
 }
 .fw-500 {
   font-weight: 500;
   font-size:1.2rem
 }
}
.pdfContainer {
  background-color: black;
  padding-top: 5%;
}
.pdf-Holder {
  background-color: #fff;
  width: 65%;
  padding-bottom: 10%;
}

.content-holder {
  text-align: center;
  padding-top: 1.5rem;
}

.summary-holder {
  display: flex;
}

.contribution-summary {
  padding-top: 1.5rem;
}

.download {
  padding-top: 1rem;
}

.modeClass{
  text-transform: capitalize;
}

@media screen and (max-width: 720px) {
.pdf-Holder {
  width: 90%;
}
.dTuRPV{
  justify-content: center;
}
}