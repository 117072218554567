.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 100%;
}
.demo-image-preview {
    width: 50%;
    height: 50%;
    text-align: center;
}

audio:focus {
    outline: none;
}
video:focus {
    outline: none;
}
audio::-webkit-media-controls-panel{
    background-color: #fff;
    width: 100%;
    border-radius: 0px;
}
audio::-webkit-media-controls-toggle-closed-captions-button{
    -webkit-appearance: media-toggle-closed-captions-button;
    // background-color: white;
}

.audio-recorder-holder{
    height: 320px;
    .audio-btn-container{
        display: flex;
        justify-content: space-between;
        padding-top: 1.4rem;
    }
    span.countdown{
        font-size: 1.3rem;
    }
    .audio-holder{
       padding-top: 2rem;

    }
}

.video-recorder-holder{
    .video-btn-container{
        display: flex;
        justify-content: space-between;
    }
    span.countdown{
        font-size: 1.3rem;
    }
}

.camera-error{
    height: 150px;
    text-align: center;
    h3{
        padding-top: 35px;
    }
}