.checkin{
    .date-container {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
    }
    .date-text{
        padding-left: 1rem;
    }
    .tab-container {
        padding: 2rem 0rem
    }
    .main-card-container{
        margin: 1.2rem 0px;
        overflow: hidden;
        border: 1px solid #EBEDF3;
    }
    .MuiBox-root-35 {
        padding-left: 0px ;
        padding-right: 0px;
    }
    .check-card{
        opacity: .3;
        cursor: not-allowed;
    }
    .icon-style {
        color: red;
        cursor: pointer;
        font-size: 13px;
    }
    .card-container{
        display: flex;
        padding: 1rem;
    }
    .time-container {
        // display: flex;
        // align-items: center;
        // justify-items: center;
        padding-right: .5rem;
        text-align: center;
        width: 30%;
        // .time-text{
        //     font-weight: 500;
        //     font-size: 14px;
        // }
    }
    .details-container {
    //     text-align: left;
    // display: flex;
    // align-items: center;
    // justify-items: center;
    padding-left: 2rem;
        .location {
            display: flex;
        height: 7px;
        width: 7px;
        border-radius: 100%;
        background-color: #4043e4;
        margin-right: 10px;
        margin-top: 7px;
        }
        .location-container{
            display: flex;
        }
    }
    .MuiBox-root-63{
        padding: 10px;
    }
    @media only screen and (max-width: 480px){
        .checkin-btn {
            padding: .6rem !important;
            padding-top: 1rem !important;
        }
        .card-container{
            display: block;
        }
        .time-container{
            padding-right: .5rem;
            text-align: unset;
            width: 100%;
        }
        .details-container {
            padding-left: .5rem;
        }
        .time-text {
            padding-left: .5rem;
        }
    }
    .PrivateSwitchBase-root-15{
        padding: 4px
    }
    .card{
        border: unset;
    }
    .bg-color {
        background-color: #252625;
    }
}
.rsvp-card{
  overflow: hidden;
  position: relative;
  .check-box{
    position: absolute;
    top: 0;
    right: 0;
  }
  .bg-green{
    background-color: #28a745;
  }
}
.bg-color {
    background-color: #252625;
}
