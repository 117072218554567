.container{
    display: flex;
    justify-content: space-between;
}
.prayer_container{
    display: flex;
    justify-content: space-evenly;
}

.containerIcon {
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 4px 16px 0px rgba(88, 103, 221, 0.15);
    background-color: #384AD7;
    border-radius: 1rem;
    padding: 7px;
    cursor: pointer;
}

.containerIconText {
    font-weight: unset;
    font-size: 1rem;
    color: #DFEBFC;
    padding-left: 0.3rem;
}

.formErrors {
    font-size: 12px;
    color: #e74c3c;
    // margin-left: 6px;
    margin-top: -3px;
    max-width: 320px;
}
.toasterColor{
    background-color: #384AD7;
}
.submitContainer {
    padding-top: 1rem;
}
.confirmButtonContainer{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.headerText{
    display: flex; 
    font-weight: 400;
    padding-bottom: 1rem;
}

.formContainer{
    padding: 1rem 2rem
}