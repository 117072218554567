.form_container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.form_container_sub {
  width: 40%;
}

.form_words_body {
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
}

.form_btn {
  display: flex;
  justify-content: end;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.form_img_container {
  height: 420px;
  display: flex;
  justify-content: space-between;
  padding: 2rem 4rem;
}
.form_img_container_height {
  height: 420px;
  padding: 2rem 6rem;
}
.form_img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.form_img_1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.form_img_carousel {
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.form_img_modal {
  width: 100%;
  height: 100%;
}

.form_header {
  font-size: 20px;
  font-weight: bolder;
  padding-bottom: 5px;
}
.dynamic_form_description {
  max-width: 20rem;
  white-space: "noWrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  .organization-container table,
  .organization-container thead,
  .organization-container tbody,
  .organization-container th,
  .organization-container td,
  .organization-container tr {
    display: block !important;
  }

  .form_img_container_height {
    padding: 1rem;
  }

  .form_container_sub {
    width: 80%;
  }

  .form_img_container {
    padding: 2rem;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .organization-container thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .organization-container tr {
    border: 1px solid #ccc !important;
  }

  .organization-container td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 50% !important;
    overflow-wrap: anywhere;
  }

  .organization-container td:before {
    /* Now like a table header */
    position: absolute !important;
    /* Top/left values mimic padding */
    top: 18px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
	*/
  /* td:nth-of-type(1):before { content: ""; } */
  .organization-container td:nth-of-type(1):before {
    content: "Name";
  }
  .organization-container td:nth-of-type(2):before {
    content: "Description";
  }
  .organization-container td:nth-of-type(3):before {
    content: "Start Date";
  }
  .organization-container td:nth-of-type(4):before {
    content: "End Date";
  }
  .organization-container td:nth-of-type(5):before {
    content: "";
  }
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  .submitted-container table,
  .submitted-container thead,
  .submitted-container tbody,
  .submitted-container th,
  .submitted-container td,
  .submitted-container tr {
    display: block !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .submitted-container thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .submitted-container tr {
    border: 1px solid #ccc !important;
  }

  .submitted-container td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 50% !important;
    overflow-wrap: anywhere;
  }

  .submitted-container td:before {
    /* Now like a table header */
    position: absolute !important;
    /* Top/left values mimic padding */
    top: 18px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
	*/
  /* td:nth-of-type(1):before { content: ""; } */
  .submitted-container td:nth-of-type(1):before {
    content: "";
  }
  .submitted-container td:nth-of-type(2):before {
    content: "Name";
  }
  .submitted-container td:nth-of-type(3):before {
    content: "Description";
  }
  .submitted-container td:nth-of-type(4):before {
    content: "Date Submitted";
  }
  .submitted-container td:nth-of-type(5):before {
    content: "";
  }
}
