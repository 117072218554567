.recurring-giving-plan-wrapper {
  .btn-primary, label {
    border-color: #ccc;
    background-color: transparent;
    color: #ccc
  }
  label>.btn-primary {
  &:hover {
    background-color: #3282b8;
    color: #FFF
  }
}
.select-input{
  height: calc(1.8em + 1.8rem + 7px);
}
.react-datepicker-wrapper{
  width: 100% !important;
}
// Mobile devices
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {}
}

// .react-datepicker__day--outside-month {
//   color: transparent;
//   // pointer-events: none;
//   visibility: hidden;
// }

// .react-datepicker__navigation {
//   display: none;
// }


.errorText {
  font-size: .85rem !important;
  font-weight: 600 !important;
  text-transform: capitalize;
} 