.give-option{
  .select-input{
    height: calc(1.8em + 1.8rem + 7px);
  }
}


.errorText {
  font-size: .85rem !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}