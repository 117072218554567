.final-give-info-container{
  text-align: left;
  // max-width: 88%;
  .final-give-info{
    display: flex;
    // justify-content: space-between;
    margin: 10px 0;
    h4{
      font-weight: 900;
      // flex: 1;
      font-size: 17px;
      font-size: 1rem;
      line-height: 1.2rem;
    }
    p{
      // flex: 2 1;
      font-size: .9rem;
      line-height: 1.2rem;
      padding-left: 10px;
      text-align: justify;
    }
  }
}