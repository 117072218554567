.toast-container{
  border-radius: 10px;
  /* padding: 5px; */
}

.toast-container2{
  padding: 10px;
}

body{
  font-family: 'Poppins', sans-serif;
}

.icon-size{
  font-size: 16px;
}

.w-full {
  width: 100%;
}

/* @media screen and (max-width: 330px){
  body{
    font-size: 10px !important;
  }
} */

@font-face {
  font-family: "General-Sans";
  src: url("./fonts/GeneralSans-Semibold.woff2") format("woff2"),
    url("./fonts/GeneralSans-Semibold.woff") format("woff"),
    url("./fonts/GeneralSans-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "General-Sans";
  src: url("./fonts/GeneralSans-Regular.woff2") format("woff2"),
    url("./fonts/GeneralSans-Regular.woff") format("woff"),
    url("./fonts/GeneralSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "General-Sans";
  src: url("./fonts/GeneralSans-Medium.woff2") format("woff2"),
    url("./fonts/GeneralSans-Medium.woff") format("woff"),
    url("./fonts/GeneralSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "General-Sans";
  src: url("./fonts/GeneralSans-Bold.woff2") format("woff2"),
    url("./fonts/GeneralSans-Bold.woff") format("woff"),
    url("./fonts/GeneralSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}