.church-411-page{
  .church411Background{
    // background-color: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
    // border: 1px solid #eee;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .custom-card{
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 0.42rem;
    .cards-header{
      display: flex;
      justify-content: space-between;
      // align-items: stretch;
      flex-wrap: wrap;
      min-height: 70px;
      padding-top: 0;
      padding-bottom: 0;
      // background-color: transparent;
      padding: 0 2.25rem;
      margin-bottom: 0;
      // background-color: #ffffff;
      border-bottom: 1px solid #EBEDF3;

      .cards-title{
        display: flex;
        align-items: center;
        margin: 0.5rem;
        margin-left: 0;
        font-weight: 500;
        font-size: 1.275rem;
        // color: #181C32;
      }
    }
  }
    
  .chruch411-grid{
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .card-user-container{
    flex: 1;
    .user-img-container{
      img{
        width: 120px;
        height: 120px;
      }
      .customize-menu{
        top: -12px;
        right: -20px;
      }
    }
  }
  .user-profile-container{
    .icon_color{
      color: #1dc9b7;
    }
    a{
      color: inherit;
    }
  }
}

@media (min-width: 720px){
  .church-411-page{
    .church411Background{
        margin-bottom: 00px;
    }
    .chruch411-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
        .card-user-container{
          flex: 1;
          .user-img-container{
            img{
                width: 100px;
                height: 100px;
            }
          }
        }
      }
    }
      
}

@media (min-width: 1200px){
  .church-411-page{
    .church411Background{
        margin-bottom: 00px;
    }
    .chruch411-grid{
        grid-template-columns: repeat(3, 1fr);
      }
    }
      
}