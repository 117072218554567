/*! CSS Used from: https://live.jesushousedc.org/index.7bc981e9.css */
button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button {
    text-transform: none;
  }
  button {
    -webkit-appearance: button;
  }
  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  h1,
  h3,
  h5,
  p {
    margin: 0;
  }
  button {
    background-color: initial;
    background-image: none;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
    border: 0 solid;
  }
  button {
    cursor: pointer;
  }
  :-moz-focusring {
    outline: auto;
  }
  h1,
  h3,
  h5 {
    font-size: inherit;
    font-weight: inherit;
  }
  button {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }
  svg {
    display: block;
    vertical-align: middle;
  }
  *,
  :after,
  :before {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    border-color: currentColor;
    --tw-blur: var(--tw-empty);
    --tw-brightness: var(--tw-empty);
    --tw-contrast: var(--tw-empty);
    --tw-grayscale: var(--tw-empty);
    --tw-hue-rotate: var(--tw-empty);
    --tw-invert: var(--tw-empty);
    --tw-saturate: var(--tw-empty);
    --tw-sepia: var(--tw-empty);
    --tw-drop-shadow: var(--tw-empty);
    --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
      var(--tw-sepia) var(--tw-drop-shadow);
  }
  h1 {
    font-size: 2.44rem;
    font-weight: 900;
    line-height: 1;
  }
  h3 {
    font-weight: 700;
    line-height: 1.25;
  }
  h3 {
    font-size: 1.56rem;
  }
  h5 {
    font-size: 1rem;
    letter-spacing: 0.025em;
  }
  h5 {
    /* text-transform: uppercase; */
    line-height: 1.25;
    --tw-text-opacity: 1;
    color: rgba(102, 102, 103, var(--tw-text-opacity));
  }
  p {
    line-height: 1.25;
    --tw-text-opacity: 1;
  }
  /* p {
    color: rgba(64, 64, 65, var(--tw-text-opacity));
  } */
  button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  button {
    cursor: default;
  }
  div {
    box-sizing: border-box;
  }
  /*! CSS Used from: Embedded */
  .cfVFAm {
    box-sizing: border-box;
  }
  .hiKefI {
    box-sizing: border-box;
    font-size: 0.84rem;
    line-height: 1.25;
    text-transform: uppercase;
    color: rgb(102, 102, 103);
  }
  .kdZkNL {
    box-sizing: border-box;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25;
  }
  .bTnGGq {
    display: flex;
  }
  div,
  span,
  h1,
  h3,
  h5,
  p {
    margin: 0px;
    padding: 0px;
    border: 0px;
    font: inherit;
    vertical-align: baseline;
  }
  * {
    box-sizing: border-box;
  }
  div,
  button {
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, "Arial Nova", Arial,
      sans-serif !important;
  }
  button {
    margin: 0px;
  }
  .koscnE {
    font-weight: 600;
    padding-inline-start: 4px;
    line-height: 1.25;
    user-select: none;
  }
  .ptrWW {
    line-height: 0;
  }
  .ptrWW svg {
    width: 20px;
    height: 20px;
  }
  .ptrWW svg path {
    fill: rgb(41, 147, 229);
  }
  .iSoNZD {
    color: rgb(41, 147, 229);
    font-size: 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 20px;
    outline: none;
    height: 40px;
    padding-block: 0px;
    padding-inline: 16px 18px;
    white-space: nowrap;
    margin: 0px;
    z-index: 10;
  }
  @media (max-width: 639px) {
    .iSoNZD {
      font-size: 13.44px;
      height: 32px;
      border-radius: 16px;
      padding-block: 0px;
      padding-inline: 12px 14px;
    }
  }
  @media (min-width: 640px) {
    .iSoNZD {
      padding-block: 0px;
      padding-inline: 16px 18px;
    }
  }
  @media (min-width: 1024px) {
    .iSoNZD {
      height: 40px;
    }
  }
  .iSoNZD:focus,
  .iSoNZD:hover {
    border: none;
    background-color: rgb(79, 172, 244);
  }
  .iSoNZD:focus div,
  .iSoNZD:hover div {
    color: rgb(255, 255, 255);
  }
  .iSoNZD:focus svg path,
  .iSoNZD:hover svg path {
    fill: rgb(255, 255, 255);
  }
  .hsclWp {
    width: 100%;
    height: auto;
    background-color: rgb(0, 0, 0);
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
  }
  @media (min-width: 640px) {
    .hsclWp {
      flex-direction: column;
      align-items: flex-start;
      -webkit-box-pack: start;
      justify-content: flex-start;
      padding: 16px;
    }
  }
  @media (min-width: 1024px) {
    .hsclWp {
      height: auto;
    }
  }
  @media only screen and (max-height: 450px) and (max-width: 450px) {
    .hsclWp {
      display: initial;
    }
  }
  .kHxTJW {
    width: 100%;
    margin-block-end: 8px;
  }
  .igOWCa {
    width: 100%;
    display: flex;
  }
  @media (min-width: 640px) {
    .igOWCa {
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-flex: 1;
      flex-grow: 1;
    }
  }
  .gRbWGH {
    padding: 8px 16px;
  }
  @media (min-width: 640px) {
    .gRbWGH {
      padding: 0px;
      place-self: flex-end;
    }
  }
  .buAwch {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    overflow: hidden;
  }
  @media (max-width: 639px) {
    .buAwch {
      align-items: flex-start;
      -webkit-box-pack: start;
      justify-content: flex-start;
    }
  }
  .buAwch:focus {
    outline: none;
    box-shadow: rgb(79, 172, 244) 0px 0px 0px 3px;
  }
  .buAwch:focus:not(:focus-visible) {
    box-shadow: none;
  }
  .dKmiSh {
    color: rgb(159, 159, 160);
  }
  @media (max-width: 639px) {
    .dKmiSh {
      color: rgb(102, 102, 103);
    }
  }
  .kUQzIR {
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25;
  }
  @media (max-width: 639px) {
    .kUQzIR {
      text-align: start;
      color: rgb(64, 64, 65);
    }
  }
  @media (min-width: 640px) {
    .kUQzIR {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
  .emoKvZ {
    color: rgb(236, 236, 236);
    font-size: 0.84rem;
    line-height: 1.25;
  }
  @media (max-width: 639px) {
    .emoKvZ {
      color: rgb(102, 102, 103);
    }
  }
  .jPelAY {
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
  .fpWHiB {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  .gNxBoE {
    text-align: center;
    padding: 4px;
    margin-inline-end: 4px;
    border-radius: 4px;
    height: 40px;
    width: 96px;
    background: rgb(45, 45, 46);
  }
  .gNxBoE:nth-last-child(1) {
    margin-inline-end: 0px;
  }
  .gsZKgw {
    line-height: 17px;
    font-weight: 700;
    font-size: 1rem;
    margin-block-start: 2px;
    color: rgb(255, 255, 255);
  }
  .eJrmZJ {
    line-height: 1.25;
    font-size: 0.67rem;
    color: rgb(159, 159, 160);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  div,
  button {
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, "Arial Nova", Arial,
      sans-serif;
  }
  /*! CSS Used fontfaces */
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-black-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-black-webfont.woff)
        format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-blackit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-blackit-webfont.woff)
        format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-bold-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-bold-webfont.woff)
        format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-boldit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-boldit-webfont.woff)
        format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-regularit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-regularit-webfont.woff)
        format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-regular-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-regular-webfont.woff)
        format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-semibold-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-semibold-webfont.woff)
        format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: Proxima Nova;
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-semiboldit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-semiboldit-webfont.woff)
        format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-black-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-black-webfont.woff)
        format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-blackit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-blackit-webfont.woff)
        format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-bold-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-bold-webfont.woff)
        format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-boldit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-boldit-webfont.woff)
        format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-regularit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-regularit-webfont.woff)
        format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-regular-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-regular-webfont.woff)
        format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-semibold-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-semibold-webfont.woff)
        format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(https://static-assets.life.church/fonts/proximanova/proximanova-semiboldit-webfont.woff2)
        format("woff2"),
      url(https://static-assets.life.church/fonts/proximanova/proximanova-semiboldit-webfont.woff)
        format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
