.menu-text{
  font-family: Poppins, Helvetica, "sans-serif" !important;
}
.menu-icon{
  font-size: 1.1rem;
}
.list-icon{
  min-width: 35px !important;
}
.accordion{
  box-shadow: none !important;
}
.accordion-summary{
  min-height: 25px;
}