.donate-wrapper {
  padding-top: 20px;
  width: 100%;
  .amountInput {
    border: none;
    font-size: 50px;
    outline: none;
    background-color: transparent;
    // border-bottom: 1px solid #fff;
    padding: 0;
  }
  .input-holder {
    width: 78%;
  }
  .input-container{
    top: 0rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .give-title {
    // bottom: -3.0rem;
    bottom: -3.0rem;
    font-size: 1.3rem;
  }

  & hr {
    top: -2.5rem;
  }

  & span {
    top: 1.0rem;
    right: -2.0rem;
    font-size: 1.3rem;
    font-weight: 700;
  }
  & .error-alert{
    z-index: 2;
    // margin-top: 50px !important;
  }
}

@media (min-width: 576px) {
  .donate-wrapper {
    & .error-alert {
      margin-top: 13px !important;
      z-index: 2;
      right: 33%;
    }
    .amountInput {
      font-size: 85px;
    }
    .give-title {
      bottom: -3.0rem;
      font-size: 2.2rem;
    }
    .input-container{
      top: 0rem;
    }
    & span {
      top: 1.5rem;
      right: -2rem;
      font-size: 1.5rem;
    }
  }
}
  