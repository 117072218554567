.celebration_card {
    border-radius: 4px;
    height: 100vh;
    /* width: 80vw; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    overflow: hidden;
    color: #fff;
    padding-top: 2rem;
    /* background: url('../Icons/birthdayBack.jpg') no-repeat center center fixed; */
    background-size: cover;
    background-color: #36454F;
  }
.celebration_card_2 {
    border-radius: 4px;
    /* height: 100vh; */
    /* width: 80vw; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-align: center;
    /* display: block; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    overflow: hidden;
    color: #fff;
    /* background: url('../Icons/birthdayBack.jpg') no-repeat center center fixed; */
    background-size: cover;
    background-color: #36454F;
  }

  
  @media only screen and (min-width: 1000px) {
    .celebration_card {
      flex-direction: row-reverse;
    }
    .celebration_card img.birthday {
      width: 100%;
      max-width: 45vw;
      max-height: 90vh;
      border-radius: 1rem;
      box-shadow: 10px 10px 28px -2px rgba(0,0,0,0.79);
    -webkit-box-shadow: 10px 10px 28px -2px rgba(0,0,0,0.79);
    -moz-box-shadow: 10px 10px 28px -2px rgba(0,0,0,0.79);
    }
  }
  
  /* @media only screen and (max-height: 640px) {
    .celebration_card {
      flex-direction: row-reverse;
    }
    .celebration_card img.birthday {
      width: 100%;
      max-width: 50vw;
      max-height: unset;
    }
  } */
  
  img.birthday {
    max-height: 70vh;
    object-fit: contain;
  }
  
  .text {
    padding: 1rem;
    color: #fff;
    padding-right: 3rem;
  }
  
  .muted {
    opacity: 0.8;
  }
  
  .space {
    height: 100px;
  }

  .white-text {
    color: #fff;
    font-size: 38px;
  }
  .white-text_2 {
    color: #fff;
    font-size: 45px;
  }
  .white-text_3 {
    font-size: 5.5rem;
    color: #fff;
  }
.fa-home {
  position: absolute;
    top: 0px;
    /* right: 30px; */
    top: 0;
    /* height: 125px; */
    /* width: 72px; */
    /* text-indent: -999em; */
    padding-top: 1rem;
    cursor: pointer;
}
.fa-arrows-alt {
  position: absolute;
    top: 0px;
    right: 60px;
    top: 0;
    /* height: 125px; */
    /* width: 140px; */
    /* text-indent: -999em; */
    padding-top: 2rem;
    cursor: pointer;
}
.fa-play, .fa-pause {
  position: absolute;
    top: 0px;
    right: 90px;
    top: 0;
    /* height: 125px; */
    /* width: 140px; */
    /* text-indent: -999em; */
    padding-top: 2rem;
    /* color: #fff; */
    cursor: pointer;
}
.slider-wrapper {
  position: relative;
}

.carousel .slide img {
  width: 90%;
}