.activeForm-wrapper {
  & .icon {
    width:52px;
    height: 52px;
  }

  .email-icon {
    top:38% !important
  }
  .other-icon {
    top: 30% !important;
  }
  .amount-icon{
    top: 32% !important;
    font-weight: 700;
    color: #b5b5c3;
  }
}
