.donate .give-title {
  text-align: center;
}

.donate .amountInput {
  font-size: 60px;
}

.card-icon {
  font-size: 35px;
}

.credit-card-smart-give {
  padding: 20px;
  display: flex;
  row-gap: 3rem;
  flex-direction: column;
  border: 1px solid #adaaaa;
  border-radius: 5px;
  box-shadow: red;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.selected-price {
  font-size: 50px;
}

@media screen and (min-width: 720px) {
  .smart-give-container {
    width: 80% !important;
  }
}

@media screen and (min-width: 1020px) {
  .smart-give-container {
    width: 60% !important;
  }
}

.smart-give-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.spinner-position {
  position: absolute;
  right: 7px;
  top: 14px;
}

.routing-number .MuiFormHelperText-root {
  font-size: 14px;
}

.memo .MuiFormHelperText-root {
  font-size: 12px;
}
