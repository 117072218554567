  
.footer{
  min-width: 90%;
  margin: 0 auto;
}
.footer .contact{
  font-size: 0.8rem;
}
.footer a{
  text-decoration: none;
  color: inherit;
  /* background-color: transparent; */
}
.social span{
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 10px 9px;
}
.social i{
  font-size: 16px;
  color: #fff
}
.social .facebook{
  background-color: #4267b2;
}
.social .twitter{
  background-color: #1da1f2;
}
.social .instagram{
  background: linear-gradient(60deg,#405de6,#5851db,#833ab4,#c13584,#e1306c,#fd1d1d);
}
.social .youtube{
  background-color: red;
}
.mobile-font-size{
  font-size: 15px;
}
/* .footer {
  font-size: 1rem;
} */
@media (min-width: 576px) {
  .footer {
    max-width: 420px;
  }
  .mobile-font-size{
    font-size: 14px;
    margin-right: 1rem;
  }
}