.cards{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    // background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
    .cards-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        min-height: 70px;
        padding-top: 0;
        padding-bottom: 0;
        // background-color: transparent;
        // padding: 2rem 2.25rem;
        margin-bottom: 0;
        // background-color: #ffffff;
        border-bottom: 1px solid #EBEDF3;
    }

}
// .cards .card-custom {
//     box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
//     border: 0;
// }

@media screen and (max-width: 720px){
    .church411-user{
        .card-body {
            padding: 2px !important
        }
        button.MuiButtonBase-root.MuiIconButton-root {
            padding: 0px;
        }
        .card{
            padding: 0px !important
        }
        .prayer-container{
            overflow-y: auto;
            height: 100vh;
        }
    }

    .walls{
        .card-body{
            padding: 5px !important
        }
    }
}

@media screen and (max-width: 330px){
    .prayer-table-page{
        .table td {
            padding: 0.5rem;
            text-align: center;
        }
    }
}

.view-image {
    img {
        width: 100%;
        max-height: 90%;
        object-fit: cover;
    }
}

.attachment-modal{
    .file-container{
        text-align: center;
        padding: 0px 1rem;
        cursor: pointer;
        padding-top: 0.75rem;

        &:active{
            background-color: #E8E8E8;
        }
    }
    .text-header {
        padding-top: 1rem;
    }
}


.view-image-walls{
    display: grid;
    grid-template-columns: 47% 47%;
    // grid-template-rows: 47% 47%;
    grid-template-rows: auto;
    column-gap: 1.5rem;
    row-gap: 2rem;
    padding-bottom: 2rem;
    cursor: pointer;
}

.view-image-wall-single {
    display: block;
}

.prayer-wall-aduio{
    width: 100%;
}

