.buttons {
  width: 90%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: auto;
  cursor:pointer;
  font-size: 11px;
}

.activeButton {
  background-color:rgb(50, 130, 184);
  color: #fff
}

.fix-mobile-line-height{
  line-height: 2.7rem;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .buttons {
    width: 100%;
  }
  .fix-mobile-line-height{
    line-height: 1.5rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  
 }
 