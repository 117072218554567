.player-wrapper {
    position: relative;
    // padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .sermon-main-container {
    grid-template-columns: 1fr 400px;
    column-gap: 0px;
    height: calc(100% - 64px);
    // grid-template-areas: "video chat";
    display: grid;
    height: auto;
    grid-auto-flow: row;
    grid-auto-rows: minmax(20px, auto);
    // grid-template-columns: repeat(12, 1fr);
    gap: 8px;
  }

  .upcoming-event-title-text{
    color: rgb(102, 102, 103);
    margin-block-end: 4px;
    box-sizing: border-box;
    font-size: 0.84rem;
    line-height: 1.25;
    text-transform: uppercase;
  }

  .upcoming-event-time-sub-container {
    padding: 8px 0px;
    display: flex;
    justify-content: space-between;
  }

  .upcoming-event-time-container {
    border-radius: 2px;
    background: rgb(246, 246, 246);
    color: rgb(64, 64, 65);
    margin-block-end: 1rem;
    padding: 0px 12px;
  }
  .upcoming-event-time-container-dark{
    border-radius: 2px;
    background: "#081b33";
    color: "#304FFF";
    margin-block-end: 1rem;
    padding: 0px 12px;
  }

  .upcoming-event-time-text {
    font-size: 16px;
    font-weight: bold;
  }

  .upcoming-event-time-desc {
    box-sizing: border-box;
    font-size: 0.84rem;
    line-height: 1.25;
    color: rgb(102, 102, 103);
  }

  @media only screen and (max-width: 1200px) {
    .sermon-main-container {
      grid-template-columns: 1fr 3200px;
    }
  }
  @media only screen and (max-width: 600px) {
    .sermon-main-container {
      display: block;
    }
  }