.credit-card-form{
  .select-container{
    width: 47%;
  }
  .card-img{
    top: 6px;
    right: 10px;
    width: 37px;
    font-size: 28px 
  }
}