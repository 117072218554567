.navigator-wrapper {
  .btn-lg {
    // background-color: rgb(50, 130, 184);
    width: 45%;
    // color: #ffffff;
    font-size: 1rem
  }

  @media (min-width: 576px) {
   
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {}
}
