.mailingIcon,
.text-icon {
  top: 43%;
  color: darkgray;
}

.text-icon {
  top: 61%;
}

.text-icon-mail {
  top: 56%
}
.mailing-page{
  .mailing-address{
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 550px) {
  .mailing-page{
    .mailing-address{
      font-size: 1rem;
    }
  }
}
