.DarkToggle.react-toggle {
	// position: absolute;
  // z-index: 2;
  // left: 20px;
  // bottom: 20px;
  .react-toggle-track{
    // width: 48px;
    // height: 20px;
  }
	.react-toggle-thumb {
		// border: none;
    // top: -1px;
    // left: 0px;
	}

	// &.react-toggle--focus .react-toggle-thumb,
	// &.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	// 	box-shadow: 0 0 2px 2px rgba(#fff, 0.5);
	// }

	// .react-toggle-track,
	// &.react-toggle--checked .react-toggle-track,
	// &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
	// &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	// 	background: $mint;
	// }

	.react-toggle-track-check {
		left: 6px;
    top: 10px
	}

	.react-toggle-track-x {
		// right: 12px;
    top: 10px;
	}

  // .react-toggle--checked .react-toggle-track {
  //     background-color: #ccc;
  //   }
  

	.react-toggle-track-check,
	.react-toggle-track-x {
		// font-size: 13px;
		// line-height: 2em;
		// height: 100%;
		// text-shadow: 0 0 #fff;
		// color: transparent;
	}
}

.react-toggle--checked .react-toggle-track {
  background-color: #ccc;
}
.react-toggle--checked:hover .react-toggle-track:hover{
    background-color: #ddd;
}
.react-toggle-thumb:hover .react-toggle--checked:hover .react-toggle-track:hover{
    background-color: #ddd;
}
