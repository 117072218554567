.nav-item{
    font-size: 16px;
}

.submitContainer {
    padding-left: 1.2rem !important;
}

@media screen and (max-width: 720px){
    .nav-item {
        font-size: 10px;
    }
    .nav.nav-tabs .nav-item {
        width: 48%;
        display: flex;
        justify-content: center;
    }
}