.alert{
  .alert-icon{
    i{
      font-size: 19px !important;
    }
  }
}

.second-modal{
  // .modal-footer{
    display: unset;
  // }
}

.text_color {
  color: #fff;
}