@media (max-width: 1200px) {
  .display-4 {
      font-size: calc(1.375rem + 1.5vw)!important;
  }
}

.error.error-6 .error-title {
  font-size: 5rem!important;
}

.error {
  height: 100vh;
}