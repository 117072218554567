.credit-card{
  .ccard-img{
    top: 12px;
    right: 10px;
    display: inline;
    font-size: 27px;
    width: 45px;
  }
  .credit-card-input{
    font-weight: 700;
  }
  .select-container{
    width: 45%;
  }
  .select-container select{
    height: 50px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.errorText {
  font-size: .85rem !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}