.my-info-page{
  .PhoneInputCountry{
    display: inline-block;
  }
  .PhoneInputCountryIcon{
    top: 3px;
    position: relative;
  }
  .left-span {
    top: 58%
  }
}

.date-component-container {
  display: flex;
  column-gap: 1rem;
  align-items: center;

  @media screen and (max-width: 600px){
    /*Big smartphones [426px -> 600px]*/
    display: block
  }
}