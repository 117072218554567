#paypal-button {
  width: 100% !important;
}

.select-form {
  color: #fff;
}

.form-width {
  max-width: 480px;
}

.paymentToggle-container {
  position: relative;
  top: 2px;
}

@media (max-width: 880px) {
  .paymentToggle-container {
    position: relative;
    top: 8px;
  }
}
