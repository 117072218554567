.mainsection-container{
  /* position: relative; */
  /* border: 1px solid rgb(192, 192, 192); */
  /* top: -15vh; */
  z-index: 1;
  margin-top: -160px;
  margin-bottom: 70px;
  /* background-color: #fff; */
  border-radius: 20px !important;
  /* box-shadow: 0px 0px 2px 0px rgb(146, 146, 146); */
}

.main-section {
    max-width: 90%;
    /* margin: 0 auto */
}
.mainsection-give{
  top: -25vh !important;
}

@media (min-width: 370px) {
 .mainsection-container {
   top: -10vh;
 }
}


@media (min-width: 576px) {
  .main-section {
    max-width: 480px;
  }
}