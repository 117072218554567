@media (max-width: 768px) {
  .gridview-container-view {
    width: 100%;
    padding: 10px 30px !important;
  }
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  .view-group-users table,
  .view-group-users thead,
  .view-group-users tbody,
  .view-group-users th,
  .view-group-users td,
  .view-group-users tr {
    display: block !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .view-group-users thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .view-group-users tr {
    border: 1px solid #ccc !important;
  }

  .view-group-users td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 50% !important;
    overflow-wrap: anywhere;
  }

  .view-group-users td:before {
    /* Now like a table header */
    position: absolute !important;
    /* Top/left values mimic padding */
    top: 18px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
	*/
  /* td:nth-of-type(1):before { content: ""; } */
  .view-group-users td:nth-of-type(1):before {
    content: "First Name";
  }
  .view-group-users td:nth-of-type(2):before {
    content: "Last Name";
  }
  .view-group-users td:nth-of-type(3):before {
    content: "Email";
  }
  .view-group-users td:nth-of-type(4):before {
    content: "Mobile";
  }
  .view-group-users td:nth-of-type(5):before {
    content: "Role";
  }
  /* td:nth-of-type(6):before { content: "Secret Alias"; } */
  .view-group-users td:nth-of-type(6):before {
    content: "";
  }
  /* td:nth-of-type(8):before { content: "Dream Vacation City"; } */
  /* td:nth-of-type(9):before { content: "GPA"; } */
  /* td:nth-of-type(10):before { content: "Arbitrary Data"; } */
}
