.chpd-give-wrapper {
  text-align: center;

  .icon {
    background-color: #FFF;
    height: 100px;
    width: 100px;

    & i {
      color: rgb(155, 153, 102);
      font-size: 4rem;
    }
  }

  .chpd_icon {
    box-shadow: rgb(182, 180, 118) 0px 0px 5px 0px;
  }
}
