.card-image-background {
  background-size:contain;
}
.card-padding {
  padding: 0.5rem !important
}
.user-image-holder {
  object-fit: cover;
  width: 100%;
  // height: 100%;
  border-radius: 20px 20px 0px 0px;
}

.mobile-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

// .small-screen-holder{
//   display: block;
// }

// .hide-large-screen {
//   display: none;
// }

.child-font-family{
  font-family: Poppins, Helvetica, "sans-serif";
  font-weight: 500;
}

  @media (min-width: 576px) {
    .user-image-holder {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 20px 30px 0px 20px;
    }
  }

  @media screen and (min-width: 720px) {
    // .h4 {
      // display: flex;
      // justify-content: center;
    // }
    .user-image-holder {
      width: 100%;
    }
   
    .header-container{
      & div {
        padding-bottom: .3rem;
      }
    }

    // .small-screen-holder {
    //   display: none;
    // }
    // .hide-large-screen {
    //   display: block;
    // }
  }
  @media screen and (min-width: 1020px) {

      .user-image-holder {
        width: 100%;
        // height: 260px;
      }

      .children-container {
        height: 400px;
      }
  }
  @media screen and (min-width: 1200px) {
 
    .user-image-holder {
      width: 100%;
      // height: 260px;
    }
    .children-container {
      height: 400px;
    }
   
  }
