.text-blue{
  color: #374afb;
}
.user-give{
  .card-icon{
    .web-width{
      width: 20px;
    }
    .mobile-width{
      width: 20px;
    }
  }
}

.symbol{
  .transparent-bg{
    background-color: inherit !important;
  }
}

#short-menu{
  .fix-focus-outline {
    &:focus{
      outline: none;
    }
  }
  .trans-border{
    border-color: transparent;
  }
}
.activeForm-wrapper {
  & .icon {
    width:52px;
    height: 52px;
  }
  .email-icon {
    top:38% !important
  }
  .other-icon {
    top: 30% !important;
  }
}
.credit-card{
  .card-img{
    top: 13px;
    right: -38%;
    display: inline;
    font-size: 25px;
  }
  .credit-card-input{
    font-weight: 700;
  }
}

.card-form{
  .card-img{
    top: 4px;
    right: 10px;
    display: inline;
    font-size: 30px;
    width: 32px;
  }
  
}

.cursor{
  cursor: pointer;
}


.new-auto-debit-enrollment{
  .react-datepicker-wrapper{
    width: 100%;
    .form-control{
      border: 1px solid rgba($color: #000000, $alpha: 0.25);
    }
  }
  .MuiTypography-body1 {
    font-size: 11px;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
  .border-danger{
    color: #F64E60;
  }
}