.show-mobile-sidebar {
  width: 180px;
  height: 100vh;
  top:0;
  z-index: 99;
  right:calc(100% - 180px);
  box-shadow: 0px 0px 5px 0px #aaa;
    transition: all 50s ease-in-out;
    -webkit-transition: all 50s ease-in-out;
    -moz-transition: all 50s ease-in-out;
    -o-transition: all 50s ease-in-out;
  }

.show-mobile-sidebar.hide {
 transform: translate(-180px, 0);
 -webkit-transform: translate(-180px, 0);
 -o-transform: translate(-180px, 0);
 -moz-transform: translate(-180px, 0);
 left: -180px
}

.show-mobile-sidebar.show {
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  overflow: hidden;
  left: 0
}

.home-navbar {
  z-index: 3;
}
.read-more-text{
  .displayed-text{
    color: #fff !important;
    line-height: 1.7rem;
  }
  .show-more{
    color: rgb(107, 223, 252);

    &:hover{
      text-decoration: underline;
    }
  }
}